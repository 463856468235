/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable consistent-return */
import axios from 'axios';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppCustomCode, WhiteLabelData, WLFacebookIntegration } from '@/models/api/response.types';
import { hexToHSL } from '@/utils/colors';
import { currentPrimaryDomain, isDev, isDevDomain, isPrimaryProduct } from '@/utils/domain';

export interface WhiteLabelContextProps {
  primaryColor: string;
  secondaryColor: string;
  licenseeUUID: string;
  appEmail: string;
  appLogo: string;
  appTitle: string;
  mainWebsite: string;
  appFreePlanKeys: any;
  appPrivacyPolicyLink: string;
  appTermsOfServiceLink: string;
  documentationLink: string;
  appDomain: string;
  rewardfulAPIKey: string;
  zapierIntegrationLink: string;
  makeIntegrationLink: string;
  canUseURLScrapper: boolean;
  facebookIntegration?: WLFacebookIntegration;
  fetchAppData: () => void;
  customCodes: AppCustomCode[];
  sourceCopyRightDisclaimer: NonNullable<WhiteLabelData['app']['sources']>['copyright_disclaimer'];
}

const WhiteLabelContext = createContext<WhiteLabelContextProps | undefined>(undefined);

const WhiteLabelProvider: React.FC<{ isHostedByWidget?: boolean; children: ReactNode }> = ({
  isHostedByWidget = false,
  children,
}) => {
  const [data, setData] = useState<WhiteLabelData | undefined>(undefined);
  const [domainSetUpCompleted, setDomainSetupCompleted] = useState<boolean>(false);

  const fetchAppData = useCallback(async () => {
    // for chat widget we need to use GPTTConfig domain param
    // that users are passing in order to make it work
    const licenseeUrl = `https://${isDev ? 'm2-dev' : 'partners'}.gpt-trainer.com/api/licensee/by_domain?domain=${
      isHostedByWidget
        ? window.GPTTConfig.domain || 'app.gpt-trainer.com'
        : isDevDomain
          ? 'xin.gpt-trainer.com'
          : window.location.hostname
    }`;

    try {
      const cachedData = sessionStorage.getItem('platform_whitelabel_data');
      let jsonData;

      if (cachedData) {
        jsonData = JSON.parse(cachedData);
      } else {
        const response = await fetch(licenseeUrl);
        if (!response.ok) {
          jsonData = {};
          sessionStorage.setItem('platform_whitelabel_data', JSON.stringify(jsonData));
          throw new Error('Network response was not ok');
        }
        jsonData = await response.json();
        sessionStorage.setItem('platform_whitelabel_data', JSON.stringify(jsonData));
      }
      setData(jsonData);

      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.defaults.baseURL = isPrimaryProduct
        ? `https://${currentPrimaryDomain}`
        : `https://${jsonData.app_domain}`;

      setDomainSetupCompleted(true);
      const primaryColor = hexToHSL(jsonData.app.primary_color);
      const { l: luminosity } = hexToHSL(jsonData.app.primary_color);
      const textPrimaryColor = luminosity < 60 ? hexToHSL('#fff') : hexToHSL('#303030');
      const secondaryColor = hexToHSL(jsonData.app.secondary_color);
      const secondaryBackground = hexToHSL(jsonData.app.background_color);

      document.body.style.setProperty('--primary', `${primaryColor.h} ${primaryColor.s}% ${primaryColor.l}%`);
      document.body.style.setProperty(
        '--text-primary',
        `${textPrimaryColor.h} ${textPrimaryColor.s}% ${textPrimaryColor.l}%`,
      );
      document.body.style.setProperty(
        '--main',
        `${secondaryColor.h} ${secondaryColor.s}% ${secondaryColor.l}%`,
      );
      document.body.style.setProperty(
        '--secondary-background',
        `${secondaryBackground.h} ${secondaryBackground.s}% ${secondaryBackground.l}%`,
      );
    } catch (error) {
      console.error('There was a problem with getting whitelabel data:', error);
    }
  }, []);

  useEffect(() => {
    fetchAppData();
  }, []);

  const contextValue = useMemo(() => {
    return {
      licenseeUUID: data?.uuid || '',
      appLogo: data?.logo_url || '',
      appEmail: data?.app?.email || '',
      appTitle: data?.app?.name || '',
      appPrivacyPolicyLink: data?.app?.privacy_policy_link || '',
      appTermsOfServiceLink: data?.app?.term_of_service_link || '',
      documentationLink: data?.app?.documentation_link || '',
      mainWebsite: data?.app?.website_url || '',
      appFreePlanKeys: data?.free_plan,
      secondaryColor: data?.app?.secondary_color || '#bfdbfe',
      primaryColor: data?.app?.primary_color || '#404258',
      appDomain: data?.app_domain || 'app.gpt-trainer.com',
      rewardfulAPIKey: data?.meta?.rewardful_key || '',
      sourceCopyRightDisclaimer: data?.app?.sources?.copyright_disclaimer,
      canUseURLScrapper:
        data?.app?.sources?.website_scraping_enabled !== undefined
          ? data?.app?.sources?.website_scraping_enabled
          : true,
      zapierIntegrationLink: data?.meta?.integrations?.zapier_url || '',
      makeIntegrationLink: data?.meta?.integrations?.make_url || '',
      facebookIntegration: data?.meta?.integrations?.facebook,
      customCodes: data?.app?.custom_code || [],
    };
  }, [data]);

  if (!domainSetUpCompleted) {
    return null;
  }

  return (
    <WhiteLabelContext.Provider value={{ ...contextValue, fetchAppData }}>
      {children}
    </WhiteLabelContext.Provider>
  );
};

const useWhitelabelData = (): WhiteLabelContextProps => {
  const context = useContext(WhiteLabelContext);
  if (!context) {
    throw new Error('useWhitelabelData must be used within a WhiteLabelProvider');
  }
  return context;
};

export { WhiteLabelProvider, useWhitelabelData };
